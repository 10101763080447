import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { resendEmailVerificationAPI } from "../../API/API";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";

const VerifyAlertMob = () => {
  const history = useHistory();
  const location = useLocation();
  const [hoverState, setHoverState] = useState(false);
  const initialBtnText = localStorage.getItem("btnText") || "Verify email";
  const [btnText, setBtnText] = useState(initialBtnText);
  const verifyEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.EmailAddress
  );
  const verifyName = useSelector(
    (state) => state.profileReducer.user.profile?.FirstName
  );
  const handleChangeEmailClick = () => {
    history.push("/Edit-Email");
  };
  useEffect(() => {
    localStorage.setItem("btnText", btnText);
  }, [btnText]);

  useEffect(() => {
    const storedBtnText = localStorage.getItem("btnText");
    if (
      storedBtnText === "Email sent" ||
      (storedBtnText === "Resend email" && location.pathname)
    ) {
      setBtnText("Resend email");
    } else {
      setBtnText("Verify email");
    }
  }, [location.pathname]);

  const handleVerifyEmail = () => {
    if (btnText === "Verify email") {
      setBtnText("Email sent");
      resendEmailVerificationAPI();
    } else if (btnText === "Resend email") {
      setBtnText("Email sent");
      resendEmailVerificationAPI();
    }
  };
  return (
    <div className="container-alert">
      <div className="alert-box">
        <div className="alert-content">
          <BsExclamationCircleFill
            style={{
              color: "#DB2828",
              fontSize: "20px",
              marginRight: "4px",
              position: "relative",
              top:"4px"
            }}
          />
           { btnText == 'Verify email' ? <span style={{ fontSize: "16px", fontWeight: "400", marginTop: "3px" }}>
          Hi{" "}
          <span style={{ color: "#2E2E2E", fontWeight: "600" }}>
            {verifyName}
          </span>
          , Verify email{" "}
          <span style={{ color: "#2E2E2E", fontWeight: "600" }}>
            {verifyEmail}
          </span>{" "}
          to secure your account.
          </span> : 
          <span style={{ fontSize: "16px", fontWeight: "400", marginTop: "3px" }}>
          Please check your{" "}
          <span style={{ color: "#2E2E2E", fontWeight: "600" }}>
            {verifyEmail}
          </span>{" "}
          to confirm the change. Your email address will not be updated until it's verified.
          </span> 
          }
          <div className="alert-actions" style={{marginTop: btnText == 'Verify email'? '25px' : ''}}>
            <button className="link-button" onClick={handleChangeEmailClick}>
              Change email
            </button>
            <button
              style={{
                background: hoverState
                  ? btnText === "Verify email" || btnText === "Resend email"
                    ? "#FFFFFF"
                    : btnText === "Email sent"
                    ? "#7E7E7E"
                    : "#1E1E1E"
                  : "#1E1E1E",
                border: "none",
                borderRadius: "4px",
                padding: "6px 12px",
                cursor: "pointer",
                color:
                  hoverState &&
                  (btnText === "Verify email" || btnText === "Resend email")
                    ? "#1E1E1E"
                    : "#FFFFFF",
                width: "133px",
                height: "33px",
              }}
              onClick={handleVerifyEmail}
              onMouseEnter={() => setHoverState(true)}
              onMouseLeave={() => setHoverState(false)}
            >
              {btnText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyAlertMob;
