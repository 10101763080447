export function formatDateByTimeStamp(timestamp) {
    try {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        
        let date;
        if (!isNaN(timestamp) && timestamp.toString().length > 10) {
            date = new Date(parseInt(timestamp));
        } else {
            date = new Date(timestamp);
        }

        if (isNaN(date.getTime())) {
            return "Invalid date";
        }

        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        
        return `${month} ${day}, ${year}`;
    } catch {
        return "Invalid date";
    }
}
