import React, { useEffect, useState } from "react";
import { Col, Row, Avatar, Badge, Rate } from "antd";
import { StarFilled, ArrowLeftOutlined } from "@ant-design/icons";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import "./styles.css";
import { getImage } from "../../../../shared/downloadAttachments";
import WS from "../../../../webSocket/defaultSocket";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import skillgig_log_msg from '../../../../assets/img/Skillgigs_Msg_logo.png'
import { DefaultsRating } from "../../../WelcomeWizard/helper";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import PrivateTag from "../../../SearchGigs/components/privateTag";
import Private from "../../../../assets/icons/Private.svg"

const ChatHeader = ({
  chatDetails,
  setshowHistory,
  createdTime,
  jobDetails,
  facilityName,
  Rating,
  Reviews,
  conversationType,
}) => {
  const [profilePic, setProfilePic] = useState();
  const [title, setTitle] = useState();
  const history = useHistory();
  const [rating, setRating] = useState(DefaultsRating);
  // const [rating, setRating] = useState(3.5);
  const [conversations, setConversations] = useState([]);
  const [time, setTime] = useState();
  const [chatType, setChatType] = useState();
  const [imageError, setImageError] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // let userProfile = JSON.parse(localStorage.getItem("userProfile"));
  let userProfile = useSelector((state) => state.profileReducer.user);
  const emailVerification = useSelector(
    (state) => state.profileReducer.user.profile.verification
  );
  const inbox = useSelector((state) => state.getChatHistoryReducer.inbox);
  
  useEffect(() => {
    if (inbox?.data?.data?.body?.conversations) {
      setConversations(inbox?.data?.data?.body?.conversations[0]);
      setTime(
        moment(inbox?.data?.data?.body?.conversations[0]?.Created).fromNow()
      );
    }
  }, [inbox]);

  useEffect(() => {
    setTime(moment(conversations?.Created).fromNow());
    const hoursDifference = moment().diff(
      moment(conversations?.Created),
      "hours"
    );
    if (hoursDifference < 25) {
      //for only last 24 hours chats
      const interval = setInterval(() => {
        setTime(moment(conversations?.Created).fromNow());
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [conversations]);

  useEffect(() => {
    if (emailVerification) {
      if (emailVerification.email) {
        setIsEmailVerified(emailVerification.email);
      }
    }
  }, []);

  useEffect(async () => {
    setRating(chatDetails && chatDetails[0]?.RecipientUser?.Profiles?.Rating);

    let profilePic = "";
    if (
      chatDetails?.length > 0 &&
      chatDetails[0].RecipientUser?.profile?.PictureUrl
    ) {
      profilePic = await getImage(
        chatDetails[0].RecipientUser.profile.PictureUrl
      );
    } else {
      //https://joeschmoe.io/api/v1/random
      // profilePic =
      //   "https://images.pexels.com/photos/220429/pexels-photo-220429.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";
    }

    setProfilePic(profilePic);
    getTitle();
  }, [chatDetails]);

  function getTitle() {
    if (chatDetails?.length > 0) {
      if (chatDetails[0].SourceTypeId == 1) {
        setTitle(chatDetails[0].SkillListingCard?.Title);
        setChatType("Received Bids");
      } else if (chatDetails[0].SourceTypeId == 2) {
        setTitle(chatDetails[0].GigCard?.Title);
        setChatType("Placed Bid");
      }
    }
  }
  let CompanyName = chatDetails[0]?.RecipientUser?.Profiles?.CompanyName;

  const truncatedCompanyName = CompanyName?.length > 20 ? `${CompanyName.substring(0, 20)}...` : CompanyName;


  const getProfileImage = () => {
    if (chatDetails[0]?.SourceTypeId === 2) {
      if (
        chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl.charAt(0) === "/"
      ) {
        return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl}`;
      }
    } else {
      if (chatDetails[0]?.SenderUser?.Profiles?.PictureUrl.charAt(0) === "/") {
        return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`;
      }
      // return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className={`chatHeaderCont ${conversationType == "Admin-Talent" ? "chatHeaderCont-admin" : null}`}
        aria-details={!isEmailVerified && "extra-top"}
      >
        <Row className="chatHeaderFlex">
          {windowDimensions.width < 768 ? (
            <Col>
              <ArrowLeftOutlined
                style={{ fontSize: "14px" }}
                onClick={() => {
                  history.goBack();
                }}
              />
            </Col>
          ) : null}
          <Col style={{ marginLeft: "3%" }}>
            <div className={`chat-card-image ${conversationType === "Admin-Talent" ? "Admin-circle-border" : null}`}>
              {(chatDetails &&
              chatDetails?.length > 0 &&
              chatDetails[0].SourceTypeId &&
              chatDetails[0].SourceTypeId == 2
                ? chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl
                : chatDetails[0]?.SenderUser?.Profiles?.PictureUrl) &&
                !imageError || conversationType === "Admin-Talent"  ? (
                <img
                  onError={() => setImageError(true)}
                  className={conversationType === "Admin-Talent" ? null : "Emp-profile-img"}
                  src={conversationType === "Admin-Talent" ? skillgig_log_msg : getProfileImage()}
                  alt="title"
                />
              ) : (
                <div className="image-container">
                  {conversationType === "Admin-Talent" ? (
                    <div className="image-text">S</div>
                  ) : (
                    <div className="image-text">
                      {chatDetails &&
                      chatDetails?.length > 0 &&
                      chatDetails[0]?.SourceTypeId == 2
                        ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName
                          ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName?.split(
                              ""
                            )[0].toUpperCase()
                          : chatDetails[0]?.RecipientUser?.UserName?.split(
                              ""
                            )[0].toUpperCase()
                        : chatDetails[0]?.SenderUser?.Profiles?.FirstName
                        ? chatDetails[0]?.SenderUser?.Profiles?.FirstName?.split(
                            ""
                          )[0].toUpperCase()
                        : chatDetails[0]?.SenderUser?.UserName?.split(
                            ""
                          )[0].toUpperCase()}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col
            xl={conversationType !== "Admin-Talent" ? 17 : null}
            lg={conversationType !== "Admin-Talent" ? 14 : null}
            sm={conversationType !== "Admin-Talent" ? 8 : null}
            xs={conversationType !== "Admin-Talent" ? 13 : null}
            style={{
              maxWidth: conversationType !== "Admin-Talent" ? "69%" : null,
              marginLeft: "2%",
            }}
          >
            <div>
              <div className="nameTitle">
                {conversationType === "Admin-Talent" ? "SkillGigs" : <div>
                  {chatDetails && chatDetails[0]?.SourceTypeId == 2
                    ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName
                      ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName +
                        " " +
                        chatDetails[0]?.RecipientUser?.Profiles?.LastName
                      : "SkillGigs"
                    : chatDetails[0]?.SenderUser?.Profiles?.FirstName
                    ? chatDetails[0]?.SenderUser?.Profiles?.FirstName +
                      " " +
                      chatDetails[0]?.SenderUser?.Profiles?.LastName
                    : "SkillGigs"}
                    <span style={{fontSize:"12px", color:"#999999", margin:"0px 2px 0 2px"}} >@</span>
                    
                    <span 
                    className="company-name"
                    onClick={() => history.push({ pathname: `/company-profile/${chatDetails[0]?.RecipientUser._id}`,state: { fromChat: true } })}
                    >
                    {truncatedCompanyName}
                    </span>
                    {
                       windowDimensions.width > 786  ?
                       <span style={{marginLeft:"4px"}}>
                         {chatDetails && chatDetails[0]?.SenderUser?.EmployeeAssociation?.includes(chatDetails[0]?.RecipientProfileId) && <img src={Private} alt="Private Tag"/>}
                       </span> :""
                    }
                </div>}
              </div>
              
              <div style={{display:"flex", alignItems:"center", height:"25px"}}>
              {conversationType !== "Admin-Talent" && (
                <div style={{display:'flex'}}>
                  <div style={{marginBottom:"15px"}}>
                  {
                       windowDimensions.width < 786  ?
                       <span >
                         {chatDetails && chatDetails[0]?.SenderUser?.EmployeeAssociation?.includes(chatDetails[0]?.RecipientProfileId) && <img src={Private} alt="Private Tag"/>}
                       </span> :""
                  }
                  </div>
                  <div className="chat-header-MsgType">{chatType}</div>
                </div>
              )}
              <span style={{color:"#C5C5C5", fontSize:"30px", margin:"0 3px 0 3px"}}>&#183;</span>
              <span xl={4} lg={5} sm={4} xs={5} className="created-time-div">
                <span
                  style={{
                    color: "#7e7e7e",
                    fontSize: windowDimensions.width < 300 ? "8px" : "10px",
                  }}
                >last active {" "}
                  {chatDetails?.length > 0 ? moment(chatDetails[chatDetails?.length - 1]?.Created)?.fromNow() : time}
                </span>
              </span>
              </div>
            </div>
          </Col>
          {conversationType === "Admin-Talent" && (
            <Col
              xl={17}
              lg={14}
              sm={8}
              xs={13}
              style={{ marginLeft: "2%", maxWidth: windowDimensions?.width > 769 ? "57%" : "40%" }}
            >
              <div className="admin-tag-chat admin-label-tag">ADMIN</div>
            </Col>
          )}
          
        </Row>
        {conversationType !== "Admin-Talent" && ( 
          <div
            className="chat-header-bottom"
            onClick={() => {
              history.push({
                pathname: `/details/${jobDetails?.JobOrderId}`,
              });
            }}
          >
            <div className="job-title-text">
              {jobDetails?.Title ?? "Gig Title"}
            </div>
          </div>
        )}   
      </div>
    </>
  );
};
export default ChatHeader;
