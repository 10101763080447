import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import TextInput from "./Components/TextInput/TextInput";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import { updateSignupProfile, talentProfileApidata } from "../../redux/actions";
import { Alert } from "antd";
import { Auth } from "../../config/aws-exports";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";
import { updateSignUpProfileApi } from "../../API/API";
import { back, getWizarFlowNumber, next } from "../Auth/components/wizardFlow";
import Loader from "../../components/Loader/Loader";
function NameScreen() {
  const ProfessionCode = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const getCurrentIndex = () => {
    return localStorage.getItem("ProfessionsCode") == "HC" ||
      ProfessionCode == "HC"
      ? 5
      : 3;
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const windowHeight = window.innerHeight - 50;
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const rating = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.StarRating
  );
  // const name1 = useSelector((state) => state.profileReducer?.talentApiData?.Profiles);
  // const rating = useSelector((state) => state.profileReducer.talentApiData?.Profiles?.StarRating);
  const user = useSelector((state) => state.profileReducer.user);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  useEffect(() => {
    getName();
  }, [profiledata]);

  const getName = async () => {
    if (profiledata?.profile?.FirstName && profiledata?.profile?.LastName) {
      setName(
        `${profiledata.profile.FirstName} ${profiledata.profile.LastName}`
      );
    } else {
      const session = await Auth.currentAuthenticatedUser();
      if (session?.attributes?.name) {
        setName(session.attributes.name);
      }
    }
  };

  useEffect(() => {
    if (apiSuccess && flag) {
      setFlag(false);
      history.push(next(getCurrentIndex()));
    }
  }, [apiSuccess]);

  const nextHandler = () => {
    // dispatch(talentProfileApidata());
    const pattern = /^[a-zA-Z\s]+$/;
    if (name === "") {
      setError("Please enter your name to continue");
    } else if (!pattern.test(name)) {
      setError("Avoid using special characters or numbers.");
    } else if (name.trim()?.split(" ")?.length < 2 || name?.length > 50) {
      setError("Name should be under 50 characters with space");
    } else {
      const pattern = /^[a-zA-Z\s]+$/;
      if (pattern.test(name)) {
        setError(null);
        setFlag(true);
        const nameParts = name.trim()?.split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];
        dispatch(
          updateSignupProfile({
            "Profiles.FirstName": firstName,
            "Profiles.LastName": lastName,
            WizardFlow: getWizarFlowNumber(getCurrentIndex()),
          })
        );
      }
    }
  };

  const backHandler = () => {
    history.push(back(getCurrentIndex()));
  };
  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;
  return (
    <div
      className="NameScreenContainer"
      // style={{ minHeight: windowHeight }}
    >
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      {profiledata?.profile?.ProfessionCode == "HC" ? (
        <div className="Profile-Completion-Parent">
          <div className="ProfileCompletion">
            <ProgressBar heading={"Tell us a bit about yourself"} />
          </div>
        </div>
      ) : (
        <ProfileCompletion
          currenPage={1}
          totalPage={10}
          title="Tell us a bit about yourself"
        />
      )}

      <TextInput
        title={"What's your name?"}
        placeholder={"ex. Gary Smith"}
        type={"Mandatory"}
        onChange={(e) => {
          setName(e.target.value);
          const pattern = /^[a-zA-Z\s]+$/;
          if (e.target.value == "" || pattern.test(e.target.value)) {
            error && setError(null);
          } else {
            setError("Avoid using special characters or numbers.");
          }
        }}
        value={name}
        error={error}
        maxLength={50}
      />
      <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        // backHandler={backBtn}
        backHandler={backHandler}
      />
    </div>
  );
}

export default NameScreen;
