import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  addGigCardFavoriteAPI,
  myFavoritesInfoAPI,
  myNewFavoritesInfoAPI,
  removefavoriteGigCardAPI,
} from "../../API/API";
import { types } from "../action-types";
import {
  catchError,
  favoriteBidUpdate,
  getFavoritesInfoFailure,
  getFavoritesInfoSuccess,
  getNewFavoritesInfoFailure,
  getNewFavoritesInfoSuccess,
  HomepageFavorite,
  HomepageFavoriteRemove,
  HomepageFavoriteUpdate,
  postFavoriteGigCardFailure,
  postFavoriteGigCardSuccess,
  removeFavoriteGigCardFailure,
  removeFavoriteGigCardReducer,
  removeFavoriteGigCardSuccess,
} from "../actions";

function* myFavoriteSaga(params) {
  try {
    const response = yield call(() => myFavoritesInfoAPI(params.payload)); // API Backend
    // 
    if (response.status === 200) {
      yield put(getFavoritesInfoSuccess(response?.data));
    } else {
      yield put(getFavoritesInfoFailure(response.data.data.body));
    }
  } catch (error) {
    console.log('error', error)
    yield put(getFavoritesInfoFailure(error));
    yield put(catchError(error));
  }
}


function* myNewFavoriteSaga(params) {
  try {
    const { page, perPage } = params.payload;
    const response = yield call(() => myNewFavoritesInfoAPI(page, perPage)); // API Backend
    if (response.status === 200) {
      yield put(getNewFavoritesInfoSuccess(response.data.data));
    } else {
      yield put(getNewFavoritesInfoFailure(response.data.data.body));
    }
  } catch (error) {
    console.log(error);
    yield put(getFavoritesInfoFailure(error));
  }
}

// Add to favorite

function* favoriteGigCardSaga(params) {
  // // 
  try {
    const response = yield call(() => addGigCardFavoriteAPI(params)); // API Backend
    // 
    if (response.status === 200) {
      yield all([
        put(postFavoriteGigCardSuccess(params.payload[2])),
        put(HomepageFavorite(params?.payload)),
      ]);
    } else {
      yield put(postFavoriteGigCardFailure(response));
    }
    // yield // 
    // if (params.payload[1] === "Gig") return;
  } catch (error) {
    yield put(postFavoriteGigCardFailure(error));
  }
}

// Remove from favorite

function* RemovefavoriteGigCardSaga(params) {
  // 
  try {
    const response = yield call(() => removefavoriteGigCardAPI(params)); // API Backend
    // 
    if (response.status == 200) {
      yield all([
        put(removeFavoriteGigCardSuccess(params.payload[0])),
        put(HomepageFavoriteRemove(params))
      ]);
    } else {
      yield put(removeFavoriteGigCardFailure(response));
    }
  } catch (error) {
    yield put(removeFavoriteGigCardFailure(error));
  }
}

export function* watchMyFavoriteSaga() {
  yield takeEvery(types.GET_FAVORITES_INFO_REQUEST, myFavoriteSaga);
}
export function* watchNewMyFavoriteSaga() {
  // yield takeEvery(types.GET_NEW_FAVORITES_INFO_REQUEST, myNewFavoriteSaga);
}
// Add to favorite
export function* watchFavoriteGigCard() {
  yield takeEvery(types.FAVORITE_GIG_CARD, favoriteGigCardSaga);
}

// Remove from favorite
export function* watchRemoveFavoriteGigCard() {
  yield takeEvery(types.REMOVE_FAVORITE_GIG_CARD, RemovefavoriteGigCardSaga);
}
