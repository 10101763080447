import React from "react";
import "./TextInput.css";
import { Input } from "antd";
import { WarningFilled } from "@ant-design/icons";
import emailAlert from "../../../../assets/icons/emailAlert.svg"
function TextInput({ title, type, placeholder, onChange, value, error, maxLength, isEmailAlert=false }) {
  return (
    <div className="TextInputParentContainer">
      <div  className="textInputContainer">
        <div  className="TextInputTitleContainer">
          <div className="TextInputTitle-field">{title}</div>
          <div className="TextInputType-field"><span>{type}</span></div>
        </div>
        <Input
          defaultValue={null}
          onChange={(e) => onChange(e)}
          autoFocus
          className={`TextInputStylefield${error ? 'Error' : ''}`}
          placeholder={placeholder}
          value={value}
          status={error ? 'error' : ''}
          help={error}
          maxLength={maxLength ?? 100}
        />
        { isEmailAlert?  <div className="email-verification-div">
               <div style={{paddingRight:"7px"}}><img  src={emailAlert} alt="email-alert" /></div>
               <p className="">
                 <span style={{ fontSize: "13px", fontWeight: "400" }}>
                 Your email will be updated, and you'll be logged out for security. Log in with your new email using the same password.
                 </span>
               </p>
          </div> : ""}
        {error && 
          <div className="errorMsg">
          <WarningFilled className="errorIcon"/><span className="errorMsg-content">{error}</span>
          </div>
        }
      </div>
    </div>
  );
}
export default TextInput;
