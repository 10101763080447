import React from 'react';
import './Receivebidicon.css'; 
import specialityIcon from "../../../../../../assets/icons/specialityIcon.svg"
import DesciplineIcon from "../../../../../../assets/icons/DesciplineIcon.svg"
import ExperienceIcon from "../../../../../../assets/icons/ExperienceIcon.svg"
import ShiftIcon from "../../../../../../assets/icons/ShiftIcon.svg"
import startDateIcon from "../../../../../../assets/icons/startDateIcon.svg"
import DurationIcon from "../../../../../../assets/icons/DurationIcon.svg"
import { formatDateByTimeStamp } from '../../../../../../shared/dateHelper';

const Receivebidicon = ({ Speciality, Descipline, Experience, Shift, StartDate, Duration }) => {
  const formatDate= formatDateByTimeStamp(StartDate);
  const durationWeeks = parseInt(Duration, 10) * 4;

  return (
    <div className="receive-card">
      {Speciality?<div className="card-item">
        <img src={specialityIcon} alt="" /> 
        <span>Speciality: {Speciality}</span>
      </div>: null
      }  
      {Descipline? <div className="card-item">
        <img src={DesciplineIcon} alt="" /> 
        <span>Discipline: {Descipline}</span>
      </div>: null }
      {Experience? <div className="card-item">
        <img src={ExperienceIcon} alt="" /> 
        <span>Experience: {Experience} {Experience === 1? "year": "years"}</span>
      </div> : null}
      {Shift? <div className="card-item">
        <img src={ShiftIcon} alt="" /> 
        <span>Shift: {Shift}</span>
      </div> : null}
      {StartDate?
      <div className="card-item">
        <img src={startDateIcon} alt="" /> 
        {/* {formatDate} */}
        <span>Start date: N/A</span>
      </div>: null}
      {Duration? 
      <div className="card-item">
        <img src={DurationIcon} alt="" /> 
        <span>Duration: {durationWeeks} week</span>
      </div>: null}
    </div>
  );
};

export default Receivebidicon;
